<template>
    <div>
        <vs-table search stripe border description :sst="true" :data="table.data" :max-items="table.length"
            :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div
                        class="flex items-center justify-between p-3 font-medium border border-solid rounded-full cursor-pointer d-theme-border-grey-light d-theme-dark-bg">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of
                            {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>
            <template slot="thead">
                <vs-th>Action</vs-th>
                <vs-th>Number</vs-th>
                <vs-th>Date</vs-th>
                <vs-th>Request By</vs-th>
                <vs-th>Reason</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                        <div class="flex vx-input-group">
                            <vs-button size="small" color="grey" icon-pack="feather" icon="icon-eye" title="Detail"
                                style="margin-right: 5px" @click="handleEdit(tr.id, 'detail')" />
                            <vs-button size="small" color="danger" icon-pack="feather" icon="icon-x-square" title="Cancel"
                                style="margin-right: 5px" @click="handleCancel(tr.id,true)" />
                        </div>
                    </vs-td>
                    <vs-td>
                        <div>Reversal Code : {{ tr.code }}</div>
                        <div>PO Code Internal : {{ tr.po_code }}</div>
                        <div>PO Code External : {{ tr.po_ext_code }}</div>
                        <div>GR Selected : {{ tr.code_gr }}</div>
                    </vs-td>
                    <vs-td>
                        <div>Request : {{ formatDate(tr.request_date) }}</div>
                    </vs-td>
                    <vs-td>
                        <div>{{ tr.request_by }}</div>
                    </vs-td>
                    <vs-td>
                        <div>{{ tr.reason }}</div>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
    </div>
</template>
<script>
import moment from "moment";
export default {
    components: {},
    props: {
        draw: {
            type: Number,
        },
        sourceWarehouseId: {
            type: Number,
        },
        poTypeId: {
            type: String,
        },
        supplierId: {
            type: Number,
        },
        transactionDate: {
            type: Date,
            default: null,
        },
        status: {
            type: String,
            default: "Draft",
        },
    },
    data() {
        return {
            deleteId: null,
            table: this.tableDefaultState(),
            checkedAll: false,
            checked: [],
            selectedRouteAssignmentToAssign: [],
            timeOuts: null,
        };
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            if (this.timeOuts) { clearTimeout(this.timeOuts) }
            this.timeOuts = setTimeout(this.getData, 900);
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        handleClose() {
            this.getData();
            this.$emit("close");
        },
        handleCreate() {
            this.customerId = null;
            this.detail = true;
        },
        handlePlan(id) {
            this.$router.push({
                name: "customer-visit-plan",
                params: { id: id },
            });
        },
        getData() {
            this.$vs.loading();
            this.$http
                .get("/api/wms/v1/wms/gr-reverse", {
                    params: {
                        limit: this.table.length,
                        page: this.table.page,
                        query: this.table.search,
                        order: this.table.order,
                        sort: this.table.sort,
                        warehouse_id: this.sourceWarehouseId,
                        status: this.status,
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.checkedAll = false;
                        this.checked = [];
                        this.selectedRouteAssignmentToAssign = [];

                        this.table.total = resp.data.total_record;
                        this.table.totalPage = resp.data.total_page;
                        this.table.totalSearch = resp.data.total_record_search;
                        this.table.length = resp.data.total_record_per_page;
                        this.table.data = resp.data.records?resp.data.records:[];
                        this.setStartEnd();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        setStartEnd() {
            let valStart = this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (
                this.table.totalSearch < this.table.total &&
                this.table.search != ""
            ) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        handleEdit(id, mode) {
            this.$router.push({
                path: '/reversal/gr/form',
                query: { id: id, mode: mode, isApproval: "Approval" },
            });
        },
        handleCancel(id) {
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to cancel this data?",
                accept: () => {
                    this.$vs.loading();
                    this.$http.patch(`/api/wms/v1/wms/gr-reverse/chg-status/canceled/`+id)
                        .then((resp) => {
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    color: "success",
                                    title: "Success",
                                    text: "The data was successfully canceled",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            } else {
                                this.$vs.notify({
                                    color: "danger",
                                    title: "Error",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            }

                            this.$vs.loading.close();
                            this.getData();
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            console.log(error);
                        });
                },
            });
        },
        utcToLocal(val) {
            if (val) {
                return moment(val)
                    .add(-7, "h")
                    .utc()
                    .local()
                    .format("DD MMM YYYY HH:mm:ss");
            }
        },
        onchangeSelectedRouteAssignmentToAssign(e, index, row) {
            if (!this.checked[index]) {
                var fitered = this.selectedRouteAssignmentToAssign.filter((d) => {
                    return d.id == row.id;
                });

                if (fitered.length == 0) {
                    this.selectedRouteAssignmentToAssign.push(row);
                }
            } else {
                this.selectedRouteAssignmentToAssign.forEach((val, i) => {
                    if (val.id == row.id) {
                        this.selectedRouteAssignmentToAssign.splice(i, 1);
                    }
                });
                this.checkedAll = false;
            }
        },
    },
    mounted() { },
    watch: {

        draw() {
            this.getData();
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('DD MMM YYYY');
            };
        },
        formatLocalNumber: () => {
            return (val) => {
                const formattedValue = parseInt(val).toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                });
    
                const valueWithoutCurrencySymbol = formattedValue.replace(/[Rp\s]/g, '');
                return valueWithoutCurrencySymbol;
            }
        },
    },
};
</script>
  
<style>
.d-inline-flex-b{
    display: inline-flex; 
    justify-content: space-between;
}
</style>